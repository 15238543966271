import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Paypal from "../components/paypal"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p> */}
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
    <Paypal />
    <br />
    <br />
    <a
      href="https://www.sandbox.paypal.com/ca/business/get-paid"
      target="_blank"
    >
      {" "}
      PayPal Sandbox
    </a>
    <br />
    sb-m43apq983042@business.example.com
    <br />
    <br />
  </Layout>
)

export default IndexPage
